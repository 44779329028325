

























































































































import {
  computed,
  defineComponent,
  onMounted,
  reactive,
} from "@vue/composition-api"
import I18nFormattedMessage from "@/components/i18n/I18nFormattedMessage.vue"
import TextField from "@/components/textFields/TextField.vue"
import { Agenda, Meeting } from "@/models/Meeting"
import PickDateTime from "@/components/pickDateTime/PickDateTime.vue"
import ExternalAttendeesAdd from "@/components/ExternalAttendeesAdd.vue"
import UsersAutoComplete from "@/views/UsersAutoComplete.vue"
import { teamsContextContainer } from "@/containers/TeamsContextContainer"
import User from "@/models/User"
import { i18nContainer } from "@/containers/I18nContainer"

interface State {
  members: Array<User>
}

export default defineComponent({
  props: {
    value: {
      type: Meeting,
    },
  },
  components: {
    I18nFormattedMessage,
    TextField,
    PickDateTime,
    ExternalAttendeesAdd,
    UsersAutoComplete,
  },
  setup(props) {
    const state = reactive<State>({
      members: [],
    })
    const { getMembers } = teamsContextContainer.useContainer()
    const { formatDateTime } = i18nContainer.useContainer()
    const meeting = computed(() => {
      return props.value
    })

    const externalUsers = computed(() => {
      if (props.value) return props.value.users.filter(u => !u.id)
    })

    const attendeesCount = computed<number>(() => {
      if (props.value) {
        const internalAttendees = props.value.users.filter(
          u => u.userId !== undefined
        )
        return internalAttendees.length
      } else return 0
    })

    const agendas = computed<Agenda[]>(() => {
      if (props.value?.agendas) {
        return props.value.agendas.filter(a => a.agenda)
      } else return []
    })

    const meetingDay = computed(() => {
      if (meeting.value?.startTime.toJsDate())
        return formatDateTime(meeting.value?.startTime.toJsDate())
      else return null
    })

    const startTimeString = computed(() => {
      if (meeting.value?.startTime.toJsDate())
        return formatDateTime(meeting.value?.startTime.toJsDate(), {
          hour: "numeric",
          minute: "numeric",
        })
      else return null
    })

    const endTimeString = computed(() => {
      if (meeting.value?.endTime.toJsDate())
        return formatDateTime(meeting.value?.endTime.toJsDate(), {
          hour: "numeric",
          minute: "numeric",
        })
      else return null
    })

    onMounted(async () => {
      state.members = (await getMembers()).map(m => new User(m))
    })

    return {
      state,
      meeting,
      externalUsers,
      attendeesCount,
      agendas,
      meetingDay,
      startTimeString,
      endTimeString,
    }
  },
})
